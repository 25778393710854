import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "box 2xl:py-5 py-3" }
const _hoisted_5 = { class: "grid grid-cols-2" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "absolute bottom-10 right-36 space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/scope-2-renewables-performance" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Scope 2 Renewable Performance ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["Scope2Filter"], {
          class: "my-4",
          countries: $setup.dataCountries,
          dataFacilities: $setup.dataFacilities.data,
          onHandleChangeCountry: $setup.handleChangeCountry
        }, null, 8, ["countries", "dataFacilities"]),
        _createVNode($setup["GaugeChart"], {
          class: _normalizeClass($setup.isLoadingScopeTwoEnergyLoad && 'isSubmitting-rounded'),
          data: $setup.dataAmountChartGetter
        }, null, 8, ["class", "data"])
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-left text-xl font-bold" }, " Scope 2 Renewables Performance ", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(['my-5 relative', _ctx.styles['container__chart']])
        }, [
          (!$setup.isLoadingScopeTwoEnergyLoad)
            ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.dataScopeTwoEnergyLoad,
                colors: ['#808080', '#808080'],
                data_2: $setup.dataScopeTwoRenewableEnergy,
                colors_2: ['#8EC63F', '#8EC63F'],
                seriesName: "Energy Consumption",
                seriesName_2: "Renewables",
                lineSeriesName: "Renewables (%)",
                lineData: $setup.dataScopeTwoLineMarker,
                lineMarker: $setup.dataScopeTwoEmissionTarget,
                percentChart: $setup.dataMaxPercentChart,
                lineMarkerName: "Target (%)",
                yTitle: "kWh",
                legendStyle: {
              position: 'right',
              fontSize: 14,
              padding: [0, 100, 100, 100],
            },
                legendLayout: "vertical",
                withExtraYScale: true,
                lineAllowExtraYScale: true
              }, null, 8, ["class", "data", "data_2", "lineData", "lineMarker", "percentChart"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn border-2 border-black hover:bg-gray-700 hover:text-white active:bg-gray-800 w-48",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                $setup.router.push(
                  `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#energy_loads`
                )
              ), ["prevent"]))
            }, " Input data manually "),
            _createElementVNode("button", {
              class: "btn btn--green-primary w-48",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                $setup.router.push(`/${$setup.SETTING_INFORMATION.path}/your-green-options`)
              ), ["prevent"]))
            }, " Your Green Options ")
          ])
        ], 2)
      ])
    ])
  ]))
}