import { defineComponent as _defineComponent } from 'vue'
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import GaugeChart from "@/components/AnyChart/GaugeChart.vue";
import MetadataModule from "@/store/modules/Metadata";
import Scope2Filter from "@ems/containers/GreenOptions/TheForm/Scope2.Filter.vue";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { computed, onMounted, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { SETTING_INFORMATION } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const dataCountries = computed(
  () => MetadataModule.dataInputManualCountriesGetter
);
const dataFacilities = reactive<any>({ data: [] });
const setDefaultFacilitiesData = (data: any) => {
  data.unshift({
    CountryId: "",
    CountryName: "",
    CountryCode: "",
    Red: false,
    Url: "",
    Latitude: 0,
    Longitude: 0,
    Value: null,
    Name: "All",
  });
};
const dataAmountChartGetter = computed(
  () => ScopeTwoEmissionModule.dataAmountChartGetter
);

const isLoadingAmountRECsChartGetter = computed(
  () => ScopeTwoEmissionModule.isLoadingAmountRECsChartGetter
);
const dataCurrentYearScopeTwoEmission = computed<string | number | Date>(
  () => ScopeTwoEmissionModule.dataCurrentYearScopeTwoEmissionGetter
);
const dataScopeTwoEnergyLoad = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoEnergyLoadGetter
);
const isLoadingScopeTwoEnergyLoad = computed(
  () => ScopeTwoEmissionModule.loadingScopeTwoEnergyLoadGetter
);
const dataScopeTwoEmissionTarget = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoEmissionTargetGetter
);
const dataScopeTwoRenewableEnergy = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoRenewableEnergyGetter
);
const dataScopeTwoLineMarker = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoLineMarkerGetter
);
const dataCurrentFacilityScopeTwoEmission = computed(
  () => ScopeTwoEmissionModule.dataCurrentFacilityScopeTwoEmissionGetter
);
const dataMaxPercentChart = computed(
  () => ScopeTwoEmissionModule.dataMaxPercentChartGetter
);
const dataCountriesId = computed(() => MetadataModule.dataCountriesIdGetter);
watch(
  () => [
    dataCurrentFacilityScopeTwoEmission.value,
    dataCurrentYearScopeTwoEmission.value,
    dataCountriesId.value,
  ],
  () => {
    ScopeTwoEmissionModule.fetchAllDataChartEmission({
      Year: dataCurrentYearScopeTwoEmission.value.toString(),
      FacilityId: dataCurrentFacilityScopeTwoEmission.value,
      CountryID: dataCountriesId.value,
    });
  }
);
onMounted(() => {
  setDefaultFacilitiesData(dataFacilities.data);
  ScopeTwoEmissionModule.fetchAllDataChartEmission({
    Year: dataCurrentYearScopeTwoEmission?.value?.toString(),
    FacilityId: "",
    CountryID: "",
  });
});
const handleChangeCountry = async (values: any) => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  dataFacilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId === values
  );
  setDefaultFacilitiesData(dataFacilities.data);
};

const __returned__ = { router, dataCountries, dataFacilities, setDefaultFacilitiesData, dataAmountChartGetter, isLoadingAmountRECsChartGetter, dataCurrentYearScopeTwoEmission, dataScopeTwoEnergyLoad, isLoadingScopeTwoEnergyLoad, dataScopeTwoEmissionTarget, dataScopeTwoRenewableEnergy, dataScopeTwoLineMarker, dataCurrentFacilityScopeTwoEmission, dataMaxPercentChart, dataCountriesId, handleChangeCountry, ColumnChart, GaugeChart, get MetadataModule() { return MetadataModule }, Scope2Filter, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, computed, onMounted, reactive, watch, get useRouter() { return useRouter }, get SETTING_INFORMATION() { return SETTING_INFORMATION } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})