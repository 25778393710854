import { defineComponent as _defineComponent } from 'vue'
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { Field, Form } from "vee-validate";
import { computed, onUnmounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import MetadataModule from "@/store/modules/Metadata";

interface Props {
  countries: any[];
  dataFacilities: any[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Scope2.Filter',
  props: {
    countries: {},
    dataFacilities: {}
  },
  emits: ["handleChangeCountry"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

const dataCurrentYearScopeTwoEmission = computed<string | number | Date>(
  () => ScopeTwoEmissionModule.dataCurrentYearScopeTwoEmissionGetter
);
const initialValues = {
  Year: dataCurrentYearScopeTwoEmission.value.toString(),
  Country: null,
  FacilityId: null,
};
const props = __props;
const { countries, dataFacilities } = toRefs(props);

const HandlechangeYear = async (values: string | number | Date) => {
  ScopeTwoEmissionModule.currentYearScopeTwoEmission(values);
};

const handleChangeFacility = async (values: any) => {
  if (!values) return;
  ScopeTwoEmissionModule.currentFacilityScopeTwoEmission(values);
};

const handleChangeDropdownCountry = async (values: any, setFieldValue: any) => {
  MetadataModule.setCurrentCountries(values);
  setFieldValue("FacilityId", null);
  emits("handleChangeCountry", values);
};

onUnmounted(() => {
  ScopeTwoEmissionModule.currentYearScopeTwoEmission(new Date());
  ScopeTwoEmissionModule.currentFacilityScopeTwoEmission("");
});

const __returned__ = { emits, dataCurrentYearScopeTwoEmission, initialValues, props, countries, dataFacilities, HandlechangeYear, handleChangeFacility, handleChangeDropdownCountry, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get Field() { return Field }, get Form() { return Form }, computed, onUnmounted, toRefs, get useRouter() { return useRouter }, get MetadataModule() { return MetadataModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})